import { AppEnv, ClubApp, Environment } from '@aaa/emember/types';

/**
 * copy/pasted from firebase project settings
 */
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBAHmViJzYIg_NV-MfhHJmJD1Op8oruWsk',
  authDomain: 'aaa-northway-2.firebaseapp.com',
  databaseURL: 'https://aaa-northway-2-default-rtdb.firebaseio.com',
  projectId: 'aaa-northway-2',
  storageBucket: 'aaa-northway-2.appspot.com',
  messagingSenderId: '467621451175',
  appId: '1:467621451175:web:0f88d6e5e873ceaedc9282',
  measurementId: 'G-07W6BWFGES',
};

export const environment: Environment = {
  production: true,
  emulator: false,
  ngServe: false,
  appEnv: AppEnv.TEST,
  cloudFunctionsURL: 'https://test.northway.millisite.com',
  cloudStorageURL: `https://storage.googleapis.com/${firebaseConfig.storageBucket}`,
  clubId: ClubApp.Northway,
  firebaseConfig: firebaseConfig,
  SMARTY_WEBSITE_KEY: '',
  paymentConfig: {
    cyberSourceSessionTimeout: 60 * 1000 * 10, // ten minutes
  },
  aaaRestWsUrl: 'https://ww2.aaa.com',
  baseHref: 'https://test.northway.millisite.com/',
  zipcode: '12301',
  shift4Key: 'pk_test_pAZibPZ33iK3idENK9mso5Hz',
  payment: {
    allowCartTypes: [],
    type: 'shift4'
  },
};
